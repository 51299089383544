.BookCoverList {
  margin-top: 32px;

  .__header {
    padding-left: 20px;
    padding-right: 20px;
  }

  .__list-overflow {
    overflow-y: hidden;
    overflow-x: auto;
  }

  .__list {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    min-width: min-content;
  }
}

.BookCover {
  width: 80px;
  height: 120px;
  margin-right: 16px;

  &:last-child {
    margin-right: 0;
  }

  img {
    pointer-events: none;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
