.grey-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;

  .commentModal {
    background-color: #FEF9F0;
    max-width: 368px;
    width: 368px;
    height: 468px;
    max-height: 468px;
    top: calc(50% - 250px);
    left: calc(50% - 200px);
    position: absolute;
    border-radius: 8px;
    display: block !important;
    padding: 16px;

    .closeIcon {
      position: absolute;
      cursor: pointer;
    }

    h1 {
      text-align: center;
      font-size: 28px;
      font-weight: 700;
      line-height: 34px;
      font-family: "Apoc Dark", sans-serif;
      margin: auto;
      padding: initial;
    }

    p {
      text-align: center;
      font-family: 'IBM Plex Mono', monospace;
      font-size: 16px;
      font-weight: normal;
    }

    textarea {
      width: 100%;
      border: 1px;
      border-block-end: 1px solid;
      border-block-start: 1px solid;
      font-size: 17px;
      font-family: -apple-system, sans-serif;
      font-weight: 400;
      line-height: 22px;
      padding: 14px 0;
      height: 210px;
      outline: none;
      resize: none;
      background-color: #FEF9F0;
    }

    .mainButton {
      cursor: pointer;
      bottom: 20px;
      background-color: RGB(0, 122, 255);
      border-radius: 8px;
      width: 100%;
      padding: 14px 0 14px 0;
      margin-top: 10px;
      display: inline-block;
      text-align: center;
      color: RGB(255, 255, 255);
    }
  }
}
