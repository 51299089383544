.ChapterAnalytics {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  box-sizing: border-box;

  h3 {
    margin: 0;
    color: rgba(0, 0, 0, 1);
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    font-family: "Apoc Dark", sans-serif;
  }

  .time {
    display: inline-flex;
    font-family: 'IBM Plex Mono', monospace;
    font-weight: 300;
    font-size: 32px;

    .colon {
      line-height: 18px;
    }

    .hours, .minutes {
      display: inline-flex;
      flex-direction: column;
      align-items: center;

      span {
        display: block;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        opacity: 0.5;
        padding-top: 10px;
      }
    }
  }

  .analytics-error {
    font-family: IBM Plex Mono, monospace;
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    line-height: 26px;
    letter-spacing: 0.35px;
    margin-bottom: 40px;
    text-align: center;
    color: #000000;
    opacity: 0.5;
  }

  .description {
    font-family: 'IBM Plex Mono', monospace;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    max-width: 165px;
    line-height: 18px;
    letter-spacing: 0.35px;

    &.grey {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  .value {
    .green {
      color: rgba(138, 197, 1, 1);
      font-size: 24px;
      padding-right: 7px;
    }

    .small {
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: rgba(0, 0, 0, 0.5);
      width: 70px;
      display: inline-block;
      text-align: left;
    }
  }

  .emoji {
    font-size: 24px;
    margin: 18px 0 12px 0;
  }


  .graphs {
    display: flex;
    justify-content: center;
    padding: 20px 0 24px 0;

    color: rgba(255, 255, 255, 1);
    background-color: rgba(0, 0, 0, 1);
    margin: 0 -20px 32px -20px;

    .mainMetric {
      display: block;
      text-align: center;
      padding: 0 10px;

      h3 {
        color: white;
        margin-bottom: 10px;
        font-size: 15px;
        line-height: 28px;
        letter-spacing: 0.35px;
        text-align: center;
        font-family: "Apoc Dark", sans-serif;
        font-style: normal;
        font-weight: 950;
      }
    }
  }

  .NotRead {
    max-width: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .time {
      margin-top: 30px;
    }

    .description {
      text-align: center;
    }
  }

  .InProgress {

    .time {
      margin-top: 50px;
    }

    .value {
      margin-top: 10px;
    }

    .circle-graph {
      &.--words-read {
        margin-top: 20px;
        margin-bottom: 40px;
      }

      &.--effective-speed {
        margin-top: 20px;
        margin-bottom: 10px;
      }
    }
  }

  .Done {
    .circle-graph {
      &.--effective-speed, &.--effective-time {
        margin-top: 20px;
        margin-bottom: 10px;
      }
    }

    .emoji {
      margin-bottom: 50px;
    }

    .words-count {
      margin-top: 30px;
      margin-bottom: 40px;
      font-family: IBM Plex Mono, monospace;
      font-size: 32px;
      font-style: normal;
      font-weight: 300;
      line-height: 18px;
      letter-spacing: 0.35px;
    }

    .hrs-mins {
      margin-top: 10px;

      .digit {
        font-family: IBM Plex Mono, monospace;
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px;
        letter-spacing: 0.35px;
        text-align: right;
      }

      .unit {
        margin-left: 2px;
        font-family: IBM Plex Mono, monospace;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.35px;
        opacity: 0.5;
        text-align: center;
      }
    }
  }
}

