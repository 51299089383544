.Grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  & > * {
    display: flex;
    box-sizing: border-box;
    min-height: 250px;
    width: 50%;
  }

  // bottom border
  & > *:nth-child(2n-1):not(:nth-last-child(-n+2))::after, & > *:nth-child(2n):not(:last-child)::after {
    content: "";
    box-sizing: border-box;
    display: block;
    width: calc(100% - 24px);
    border-bottom: 1px solid black;
    height: 20px;
    flex-grow: 1;
  }

  // add to two last el padding instead of margin
  & > *:nth-last-child(-n+2)::after,  & > *:last-child {
    padding-bottom: 20px;
  }

  // left
  & > *:nth-child(2n-1):not(:nth-last-child(-n+2))::after {
    align-self: flex-end;
  }

  // right
  & > *:nth-child(2n):not(:last-child)::after {
    align-self: flex-start;
  }

  & > *:nth-child(odd){
    border-right: 1px solid black;
  }

  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow: hidden;
  }

}
