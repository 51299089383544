.Book {
    position: relative;
    width: 100%;

    .info {
        height: 25px;
        padding: 0 20px;
        position: relative;
        display: inline-table;
        width: -webkit-fill-available;

        div.leftBlock {
            display: inline;

            span {
                &.iconTime {
                    margin-left: 15px;
                    background: url("/assets/img/time.svg") no-repeat 0px 5px;
                    padding-left: 15px;
                }
            }
        }

        div.rightBlock {
            width: -webkit-fill-available;
            display: contents;

            span.averageMetricValue {
                float: right;
                padding-left: 20px;

                svg {
                    width: 24px;
                    height: 24px;
                    padding-right: 5px;
                    margin-bottom: -6px;
                }

                strong {
                    color: rgba(0, 0, 0, 0.6);
                }
            }
        }
    }

    .arrow {
        cursor: pointer;
        width: 36px;
        height: 78px;
        justify-content: center;
        align-items: center;
        display: flex;
        position: fixed;
        top: 50%;
        margin: 0 10px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        border-radius: 20px;

        img {
            height: 16px;
            width: 10px;
        }

        &.leftArrow {
            left: 0;
        }

        &.rightArrow {
            right: 0;
        }
    }
}
