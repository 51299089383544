.noData {
  top: 50%;
  transform: translate(0, -50%);
  position: absolute;
  text-align: center;
  width: calc(100% - 40px);
  padding: 20px;

  .title {
    font-weight: bold;
    padding-bottom: 20px;
  }
}

.BookAnalytics {
  #mainMetrics {
    color: rgba(255, 255, 255, 1);
    background-color: rgba(0, 0, 0, 1);
    margin-bottom: 32px;

    .graphs {
      display: flex;
      justify-content: center;
      padding-bottom: 20px;

      .mainMetric {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 0 10px;

        h3 {
          margin-bottom: 10px;
          font-size: 15px;
          line-height: 28px;
          letter-spacing: 0.35px;
          text-align: center;
          font-family: "Apoc Dark", sans-serif;
          font-style: normal;
          font-weight: 950;
        }
      }
    }

    .legend {
      display: flex;
      justify-content: space-around;
      font-size: 12px;
      line-height: 18px;
      font-family: 'IBM Plex Mono', monospace;
      text-align: center;
      padding-bottom: 30px;

      div {
        display: inline-block;
        padding: 0 40px;

        .sphere {
          padding: 0;
          transform: rotate(45deg);
        }

        &.average svg {
          margin-bottom: -2px;
        }

        span {
          margin-left: 10px;
        }
      }
    }
  }

  .stats {
    color: rgba(0, 0, 0, 1);

    h3 {
      margin: 0;
      color: rgba(0, 0, 0, 1);
      font-size: 15px;
      line-height: 18px;
      font-family: "Apoc Dark", sans-serif;
    }

    .circleGraph {
      padding-top: 17px;

      &.readability {
        padding-bottom: 10px;
        padding-top: 10px;
      }
    }

    .value {
      padding-top: 10px;
      font-family: 'IBM Plex Mono', monospace;
      font-size: 32px;
      line-height: 18px;
      letter-spacing: 0.35px;
      font-weight: 300;
      font-style: normal;

      &.beforeEmoji {
        padding-top: 30px;
        display: inline-flex;

        .predictionHours {
          span {
            display: block;
            font-family: 'IBM Plex Mono', monospace;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            opacity: 0.5;
            padding-top: 10px;
          }
          margin-right: 20px;
        }

        .predictionMinutes {
          span {
            display: block;
            font-family: 'IBM Plex Mono', monospace;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            opacity: 0.5;
            padding-top: 10px;
          }
        }
      }

      & > .greenValue {
        color: rgba(138, 197, 1, 1);
        font-size: 24px;
        padding-right: 7px;
      }

      & > .small {
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: rgba(0, 0, 0, 0.5);
        width: 70px;
        display: inline-block;
        text-align: left;
      }

      & > .grey {
        font-size: 12px;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.5);
        padding-left: 5px;

        &.only {
          position: absolute;
          height: 26px;

          & > span {
            position: absolute;
            bottom: 0;
          }
        }
      }
    }

    .bottomBlockWrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      flex-grow: 2;
      width: 100%;
    }

    .emoji {
      display: block;
      padding-top: 20px;
      padding-bottom: 12px;
      color: rgba(0, 0, 0, 1);
      font-size: 24px;
      line-height: 35px;
    }

    .description {
      font-family: 'IBM Plex Mono', monospace;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.35px;
      max-width: 80%;

      &.grey {
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}
