.Details {
    padding: 0 24px;

    & .__description {
        font-family: 'IBM Plex Mono', monospace;
        margin-bottom: 32px;
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
            margin: 0;
        }

        .__cover {
            height: 114px;
            margin-bottom: 16px;
        }

        .__author {
            font-size: 12px;
            color: rgba(0,0,0,0.5);
            margin-bottom: 8px;
        }

        .__title {
            padding: 0;
            margin: 0;
            font-family: 'IBM Plex Mono', monospace;
            font-size: 20px;
            line-height: 25px;
            font-weight: 400;
            text-align: center;
        }
    }

    .props {
        margin-bottom: 24px;

        p {
            margin: 8px 0 0 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            span {
                color: black;
                font-family: 'IBM Plex Mono', monospace;
                font-size: 17px;
                font-weight: 400;

                &:last-child {
                    opacity: 0.45;
                }
            }
        }
    }

    .__page-analytics-link {
        width: 218px;
        height: 32px;
        color: #007AFF;
        margin: 0 auto;
        border: 1px solid #007AFF;
        background: transparent;

        font-family: 'IBM Plex Mono', monospace;
        font-size: 15px;
        letter-spacing: -0.408px;
        text-transform: uppercase;
        text-decoration: none;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    hr {
        border: none;
        border-bottom: 0.5px solid black;
        padding: 0;
        margin: 24px 0 0 0;
        opacity: 0.25;
    }

    img {
        pointer-events: none;
    }
}

.DetailsMetricGraph {
    display: block;
    margin-top: 32px;
    text-decoration: none;

    .__header {
        display: flex;
        align-items: flex-start;
        white-space: nowrap;
        overflow: hidden;
        line-height: 22px;
        margin-bottom: 20px;
        padding-bottom: 0;

        img {
            height: 22px;
        }
    }

    .__graphs {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

    }

    .__line-chart{
        font-size: 12px;
    }

    .__circle-with-thumbs {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .__thumbs {
        display: flex;
        margin-top: 10px;
        width: 80px;
        height: 28px;
        flex-direction: row;
        justify-content: space-between;

        .up {
            cursor: pointer;
            display: block;
            width: 30px;
            height: 30px;
            background: url("/assets/img/thumbsup_not_active.svg") no-repeat 0 5px;

            &.active {
                cursor: initial;
                background: url("/assets/img/thumbsup_active.svg") no-repeat 0 5px;
            }
        }

        .down {
            cursor: pointer;
            display: block;
            height: 30px;
            width: 30px;
            background: url("/assets/img/thumbsdown_not_active.svg") no-repeat 0 5px;

            &.active {
                cursor: initial;
                background: url("/assets/img/thumbsdown_active.svg") no-repeat 0 5px;
            }
        }
    }
}
