.MetricMessage {
  .__explanation {
    box-sizing: border-box;
    width: 100%;
    margin: 28px auto 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 36px;
    font-family: IBM Plex Mono, monospace;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    text-align: center;
    line-height: 22px;
    color: #000000;
    opacity: 0.5;
  }
}
