.Reread {
  width: 200px;
  height: 32px;
  margin: 0 auto 32px;
  color: white;
  font-family: IBM Plex Mono, monospace;
  font-weight: 400;
  font-size: 15px;
  background-color: #007AFF;
  text-decoration: none;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
