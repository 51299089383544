.Page {
    display: block;
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 55px 10px;
    margin-block-start: 0;
    margin-block-end: 0;
    margin: 15px auto;
    transform-origin: top left;
    position: absolute;
    overflow: hidden;

    h1 {
        font-size: 2em;
        line-height: 1.33;
    }

    h2 {
        font-size: 1.5em;
        line-height: 1.33;
    }

    h3 {
        font-size: 1.17em;
        line-height: 1.33;
    }

    .paragraph {
        position: relative;
        text-indent: 0 !important;

        .paragraphInner {
            word-break: break-word;
            border-radius: 4px;
            padding: 0 5px;

            img {
                max-width: 100%;
            }

            p {
                margin-block-start: calc(0.5em - 2px);
                margin-block-end: calc(0.5em - 2px);
            }
        }

        &:first-of-type {
            p:first-of-type {
                margin-block-start: 1em;
            }
        }

        &:last-of-type {
            p:last-of-type {
                margin-block-end: 1em;
            }
        }
    }
}
