.SmallPageList {
  padding: 0 24px;
  margin-top: 16px;
  margin-bottom: 24px;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;

  .scrollContainer {
    display: flex;
    margin: 16px auto 0;
  }
}

.SmallPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 16px;

  &.--blue-border {
    margin: -2px 14px -2px -2px;

    .__metrics {
      border: rgb(0, 122, 255) solid 2px;
      height: 120px;
      width: 58px;
      z-index: 1;
      cursor: initial;
    }

    .__number {
      margin-top: 10px;
    }
  }

  .__metrics {
    box-sizing: border-box;
    height: 116px;
    width: 54px;
    padding: 0 5px;
    background-color: #FFFFFF;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    box-shadow: 5px 4px 16px rgba(0, 0, 0, 0.13);
  }

  .__number {
    font-size: 14px;
    margin-top: 12px;
  }

  .__metricValue {
    display: block;
    font-family: IBM Plex Mono, monospace;
    font-size: 14px;

    img {
      height: 14px;
    }
  }

  hr {
    margin: 0;
    width: 100%;
    opacity: 0.15;
  }

}

.r-segmented-control {
  font-family: IBM Plex Mono, monospace;
  background-color: transparent;
  border: none;
  height: auto;
  display: block;

  & > ul {
    background-color: rgba(118, 118, 128, 0.12);
    border-radius: 8px;
    margin: 0 24px;
    padding: 0;

    & > li.base {
      border-radius: 7px !important;
      margin: 2px;
      color: black;
      border: 2px solid transparent !important;
      background-color: transparent !important;
      //border: 2px solid rgba(118, 118, 128, 0.12) !important;

      &.selected {
        color: black !important;
        background-color: white !important;
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.12), 0 3px 1px rgba(0, 0, 0, 0.04);
      }
    }
  }

}
