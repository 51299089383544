.webPage {
    background-color: #FEF9F0;
    color: rgba(0, 0, 0, 0.6);

    h1 {
        margin-left: 0;
        padding-bottom: 10px;
        font-family: "Apoc Dark", sans-serif;
        font-weight: 700;
        font-size: 28px;
        letter-spacing: 0;
        line-height: 34px;
        color: rgba(0, 0, 0, 1);
    }

    h2 {
        text-align: left;
        font-size: 22px;
        line-height: 28px;
        margin: 0;
        padding-bottom: 20px;
        font-family: "Apoc Dark", sans-serif;
        font-weight: 700;
        color: rgba(0, 0, 0, 1);

        img {
            margin-bottom: -5px;
            padding-right: 5px;
        }
    }

    h3 {
        line-height: 28px;
        font-size: 15px;
        font-weight: bold;
        font-family: "Apoc Dark", sans-serif;
    }
}
